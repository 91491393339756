<template lang="pug">
summarytable(:interface_name='interface_name')
</template>

<script>
import summarytable from '@/components/summarytable';
export default {
    components: { summarytable },
    data() {
        return {
            interface_name: 'root',
        };
    },
    mounted() {},
};
</script>
