<template lang="pug">
  div
    .modal(:class='{ active: show }')
      a.modal-overlay(@click='close()')
      .modal-container
        .modal-header
          .columns
            .column.col-8
              h5 {{ title }}
            .column
              .btn.float-right.btn-primary(@click='close()')
                i.mdi.mdi-16px.mdi-close

        .modal-body
          .columns.mb-2
            .column
              p {{ message }}
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('modal_closed', true);
    },
  },
};
</script>

<style scoped></style>
