<template lang="pug">
  v-container(fluid)
    v-row(justify='end')
      .center-block
        v-select.mr-2(
          height='40',
          placeholder='Тип кампании'
          clearable,
          dense,
          outlined,
          v-model='Type',
          :items='[]',
          item-value='id',
          item-text='name',
          multiple,
          hide-details,
          no-data-text='Нет данных'
          append-icon='mdi-chevron-down',
          :menu-props='{ bottom: true, offsetY: true }',
          v-if='interface_name === "root"'
        )
        v-select.filter-btn.mr-2(
          height='40',
          outlined,
          dense,
          clearable,
          v-model='agency',
          :items='getAgencies'
          item-value='id',
          item-text='name',
          hide-details,
          placeholder='Агентство',
          no-data-text='Нет данных',
          append-icon='mdi-chevron-down',
          :menu-props='{ bottom: true, offsetY: true }',
          v-if='interface_name === "root"'
        )
        v-select.filter-btn.mr-2(
          outlined,
          dense,
          clearable,
          height='40',
          v-model='SSP',
          :items='getSummary.data'
          item-value='name',
          item-text='name',
          placeholder='SSP',
          hide-details,
          no-data-text='Нет данных',
          v-if='interface_name === "agency"',
          append-icon='mdi-chevron-down',
          :menu-props='{ bottom: true, offsetY: true }'
        )
        v-select.filter-btn.mr-2(
          height='40',
          outlined,
          dense,
          clearable,
          v-model='account',
          hide-details
          :items='getAllAccounts'
          item-value='id',
          item-text='name',
          no-data-text='Нет данных'
          placeholder='Аккаунт',
          append-icon='mdi-chevron-down',
          :menu-props='{ bottom: true, offsetY: true }',
        )
        v-col(cols='4', md='5')
          v-menu(
            ref='menu',
            v-model='menu'
            :close-on-content-click='false',
            transition='scale-transition',
            offset-y='',
            min-width='auto'
          )
            template(v-slot:activator='{ on, attrs }')
              v-text-field(
                height='40',
                v-model='dateFormatted',
                prepend-icon='mdi-calendar',
                readonly='',
                v-bind='attrs',
                v-on='on',
                dense,
                outlined,
                hide-details
              )
            v-date-picker(
              header-color='accent',
              v-model='date',
              show-adjacent-months,
              range,
              locale='ru-ru',
              first-day-of-week='1'
            )
    v-row.mr-0(justify='end')
      export(
        :full='true',
        :data='Export()',
      )
    div.mt-4
      v-data-table(
        :headers='headers',
        :items='SummaryPage',
        :loading='isLoading',
        :hide-default-header='SummaryPage.length < 1',
        hide-default-footer
      )
        template(v-slot:no-data)
          .text-center.no-data_background.pa-10.mt-4
            v-icon.mt-14(size='48', color='#BDBDBD') mdi-clock
            h2.mt-4 Нет данных за данный период
            h3.mt-2 Выберите другую дату или агентство/рекламодателя

</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import pagination from '@/components/pagination';
import ExportTable from '@/components/export_table';
import modal from '@/components/Modal';
import {ACCOUNTS, AGENCY_DATA, SUMMARY_INFO} from '@/store/const/root';
import loading from '@/mixins/loading_mixin';

export default {
  components: {
    pagination: pagination,
    export: ExportTable,
    modal,
  },
  props: {
    interface_name: {
      required: true,
      type: String,
    },
  },
  mixins: [loading],
  data() {
    return {
      menu: false,
      SSP: '',
      date: [],
      CurrentPage: 1,
      agency: '',
      account: '',
      Type: '',
      SearchToolbar: {
        show: false,
      },
      Pagination: {
        pageSize: 15,
        pageSizes: [15, 20, 30],
        currentPage: 1,
      },
      headers: [
        {text: 'Наименование SSP', value: 'name', align: 'left', width: 200},
        {text: 'Показы', value: 'win', align: 'right'},
        {text: 'CTR', value: 'ctr', align: 'right'},
        {text: 'Бюджет', value: 'budget', align: 'right'},
        {text: 'Расходы', value: 'cost', align: 'right'},
        {text: 'Прибыль', value: 'profit', align: 'right'}
      ],
    };
  },
  watch: {
    date() {
      this.Search();
    },
    agency() {
      this.account = '';
      this.ACCOUNTS({agency: this.agency, session_type: this.interface_name})
      this.Search();
    },
    account() {
      this.Search();
    },
    Type: {
      handler: function () {
        this.Search();
      },
    },
  },
  computed: {
    ...mapGetters('Root', ['getAllAccounts', 'getSummary', 'getAgencies']),
    ...mapGetters('Auth', ['getCurrentLocation']),
    dateFormatted() {
      let total = ''
      if (!this.date.length) {
        return total
      }
      total += this.date[0] < this.date[1]
        ? this.$moment(this.date[0]).format('DD.MM.YYYY')
        : this.$moment(this.date[1]).format('DD.MM.YYYY');
      total += ' - '
      total += this.date[0] < this.date[1]
        ? this.$moment(this.date[1]).format('DD.MM.YYYY')
        : this.$moment(this.date[0]).format('DD.MM.YYYY');
      return total;
    },
    SummaryPage() {
      return this.getSummary.data.map((row) => {
        if (row.name === this.SSP || this.SSP === '') {
          return {
            name: row.name,
            win: row.win,
            ctr: parseFloat(((row.click / row.load || 0) * 100).toFixed(2)),
            budget: parseFloat((row.cost + row.profit).toFixed(2)),
            cost: parseFloat(row.cost.toFixed(2)),
            profit: parseFloat(row.profit.toFixed(2)),
          };
        }
      });
    },
  },
  mounted() {
    this.setLoadingActions()
    this.agency = this.getSummary.agency;
    this.account = this.getSummary.account;
    this.date = this.getSummary.dates;
    if (this.getSummary.dates.length === 0) {
      this.date[0] = this.$moment().startOf('month').format();
      this.date[1] = this.$moment().endOf('month').format();
    }
    this.mountedFunc(this.date);
    if (this.interface_name !== 'root') {
      this.$set(this, this.interface_name, this.getCurrentLocation)
    }
  },
  methods: {
    ...mapActions('Root', [SUMMARY_INFO, AGENCY_DATA, ACCOUNTS]),
    setLoadingActions() {
      this.actions = [
        ACCOUNTS,
        SUMMARY_INFO
      ];
    },
    async mountedFunc() {
      if (this.interface_name === 'root') {
        await this.AGENCY_DATA()
        if (this.isError(AGENCY_DATA)) {
          this.$notify({
            type: 'error',
            text: 'Ошибка при получении данных'
          })
        }
      }
    },
    async Search() {
      let params = {
        date: {
          start: this.$moment(this.date[0]).valueOf(),
          stop: this.$moment(this.date[1]).set({hour: 23, minute: 59, second: 59}).valueOf(),
        },
      };

      if (this.agency !== '') {
        params.agency = this.agency;
      }
      if (this.account !== '') {
        params.account = this.account;
      }

      params.session_type = this.interface_name
      await this.SUMMARY_INFO(params)
      if (this.isError(SUMMARY_INFO)) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Ошибка сервера'
        })
        return
      }
      this.CurrentPage = 1;
    },
    Export() {
      return this.getSummary.data.map((row) => {
        return {
          [this.headers.name]: row.name,
          [this.headers.win]: row.win,
          [this.headers.budget]: (row.cost + row.profit).toFixed(2),
          [this.headers.cost]: row.cost.toFixed(2),
          [this.headers.profit]: row.profit.toFixed(2),
        };
      });
    },
  },
};
</script>

<style lang="scss">
.v-data-table th {
  color: #909399 !important;
  font-size: 16px !important;
}

.filter {
  text-align: right;

  &-btn {
    width: 150px !important;
  }

  &-datepicker {
    width: 215px !important;
  }
}

@media screen and(max-width: 840px) {
  .filter {
    &-btn {
      width: calc(33% - 0.4rem);
    }

    &-datepicker {
      width: calc(100% - 0.8rem-188px);
    }
  }
}
</style>
