<template lang="pug">
  ul.pagination
    li.page-item(v-bind:class='{ disabled: Current == 1 }', @click='ChangePage(-1)')
      a(href='#') Назад
    li.page-item(
      v-if='Math.ceil(Pages / count) <= 10',
      v-for='page in Math.ceil(Pages / count)',
      v-bind:class='{ active: page == Current }',
      @click='SetPage(page)'
    )
      a(href='#') {{ page }}
    li.page-item(
      v-if='Math.ceil(Pages / count) >= 10',
      v-for='page in CalcPages()',
      @click='SetPage(page)',
      v-bind:class='{ disabled: page == "...", active: page == Current }'
    )
      a(href='#') {{ page }}
    li.page-item(v-bind:class='{ disabled: Current == Math.ceil(Pages / count) }', @click='ChangePage(1)')
      a(href='#') Вперед
</template>

<script>
export default {
  props: {
    pages: {
      type: Number,
      required: true,
    },
    current: {
      type: Number,
      default: 1,
    },
    count: {
      type: Number,
      default: 15,
    },
  },
  data() {
    return {
      Current: this.current,
    };
  },
  computed: {
    Pages: function () {
      return this.pages;
    },
  },
  methods: {
    CalcPages() {
      const result = [];
      let i = 0;
      result.push(1);
      if (this.Current < 4) {
        for (i = 2; i < 5; i++) {
          result.push(i);
        }
        result.push('...');
      }
      if (this.Current >= 4 && this.Current <= Math.ceil(this.Pages / this.count) - 3) {
        result.push('...');
        for (i = this.Current - 2; i < this.Current + 3; i++) {
          result.push(i);
        }
        result.push('...');
      }
      if (this.Current > Math.ceil(this.Pages / this.count) - 3) {
        result.push('...');
        const to = Math.ceil(this.Pages / this.count) - 3;
        for (i = to; i < Math.ceil(this.Pages / this.count); i++) {
          result.push(i);
        }
      }
      result.push(Math.ceil(this.Pages / this.count));
      return result;
    },
    ChangePage(delta) {
      if (delta === -1 && this.Current <= 1) return;
      if (delta === 1 && this.Current >= Math.ceil(this.Pages / this.count)) return;
      this.Current += delta;
      this.$emit('update:current', this.Current);
    },
    SetPage(value) {
      if (value === '...') {
        return void 0;
      }
      this.Current = value;
      this.$emit('update:current', this.Current);
    },
  },
};
</script>
